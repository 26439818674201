.todo-page {
  padding: 20px;
  max-width: 960px;
  margin: 0 auto;
  background-color: #f0f8ff; /* 페이지 배경을 연한 파스텔 블루로 설정 */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;

  h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center; /* 제목을 중앙 정렬 */
  }

  .todo-container {
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}
