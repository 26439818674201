.todo-list {
  list-style: none;
  padding: 0;
  margin: 0; /* 상단 마진 제거 */

  .todo-list__item {
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: background-color 0.3s ease, opacity 0.3s ease;

    &--completed {
      background-color: #d3f0e4;
      opacity: 0.7;

      .todo-list__title,
      .todo-list__description {
        text-decoration: line-through; /* 제목과 설명에 줄 긋기 */
      }
    }

    .todo-list__checkbox {
      margin-right: 15px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      accent-color: #4caf50;
    }
    .todo-list__content {
      flex: 1;
    }

    .todo-list__title {
      font-size: 18px;
      font-weight: bold;
      color: #2c3e50;
      margin: 0 auto;
    }

    .todo-list__description {
      font-size: 16px;
      color: #555;
      margin: 0;
    }

    .todo-list__actions {
      display: flex;
      gap: 10px;
      margin-left: 15px;

      .todo-list__button {
        padding: 8px 12px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.3s ease, opacity 0.3s ease;
        text-decoration: none; /* 버튼에는 줄 긋기 방지 */

        &--edit {
          background-color: #4caf50;
          color: #fff;
        }

        &--delete {
          background-color: #f44336;
          color: #fff;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .todo-list__form-container {
    flex: 1;
    width: 100%;
  }
}
