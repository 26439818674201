.footer {
  background-color: #d3f0e4; /* 헤더와 동일한 파스텔 그린 색상 */
  text-align: center;
  padding: 15px 0;
  border-radius: 10px; /* 모서리를 둥글게 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 부드러운 그림자 추가 */
  margin: 0 auto;
  margin-top: 20px; /* 상단 여백 및 중앙 정렬 */
  max-width: 960px; /* 최대 너비를 헤더와 맞춤 */
  margin-bottom: 20px;
}

.footer p {
  margin: 0;
  font-size: 16px; /* 글자를 조금 더 키움 */
  font-weight: bold; /* 글자를 굵게 설정 */
  color: #2c3e50; /* 다크 그린 톤의 색상 */
}
