.date-page {
  padding-top: 20px;

  .date-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 24px;
    margin: 0;
    color: #333;
  }

  .back-button {
    background-color: #a8dfc1; /* 이전달, 다음달 버튼과 동일한 배경색 */
    color: #2c3e50; /* 이전달, 다음달 버튼과 동일한 텍스트 색상 */
    border: none;
    padding: 10px 20px; /* 버튼 크기와 패딩을 동일하게 설정 */
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #8ecfad; /* 호버 시 동일한 색상 */
    }
  }
}
