.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* 화면 일부 높이 사용 */
  flex-direction: column; /* 세로 정렬 */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #4caf50; /* 스피너 색상 */
  animation: spin 1s ease infinite; /* 회전 애니메이션 */
  margin-bottom: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.message {
  font-size: 18px;
  color: #333;
}

/* 미디어 쿼리: 태블릿 환경 */
@media (max-width: 1024px) {
  .spinner {
    width: 28px;
    height: 28px;
    margin-bottom: 15px;
  }

  .message {
    font-size: 16px;
  }
}

/* 미디어 쿼리: 모바일 환경 */
@media (max-width: 480px) {
  .spinner {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
  }

  .message {
    font-size: 14px;
  }
}
