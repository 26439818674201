.diary-display {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  h3 {
    margin-top: 0;
    font-size: 20px;
    color: #333;
  }

  p {
    font-size: 16px;
    color: #555;
    margin: 5px 0; /* 상하 마진을 줄임 */
  }

  button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    transition: background-color 0.3s ease, opacity 0.3s ease;

    &.edit-button {
      background-color: #4caf50; /* 연한 파스텔 초록색 */
      color: white;
    }

    &.delete-button {
      background-color: #f44336; /* 연한 파스텔 빨간색 */
      color: white;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
