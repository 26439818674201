.todo-form {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #ffffff; /* 색상을 약간 다른 톤으로 설정 */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, opacity 0.3s ease;

  &--editing {
    background-color: transparent; /* 수정 모드일 때 배경색 제거 */
    box-shadow: none; /* 수정 모드일 때 그림자 제거 */
    padding: 0; /* 수정 모드일 때 패딩 제거 */
    margin-bottom: 0; /* 수정 모드일 때 하단 여백 제거 */
    border: none; /* 수정 모드일 때 테두리 제거 */
  }

  .todo-form__fields {
    display: flex;
    align-items: center;
    gap: 10px; /* 필드 간의 간격을 조정 */
  }

  .todo-form__field {
    flex: 1;

    input.todo-form__input {
      width: 100%;
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      box-sizing: border-box;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: #4caf50; /* 포커스 시 테두리 색상 */
        outline: none;
      }
    }
  }

  .todo-form__button {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    background-color: #4caf50;
    color: #fff;
    transition: background-color 0.3s ease, opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  .todo-warning-message {
    color: red;
    font-size: 14px;
    margin-bottom: 0px;
  }
}
